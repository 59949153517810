<template>
  <div>
    <loader v-if="loading" />

    <iframe
      v-if="!loading && !pixelPaperDisconnect"
      id="pixel-paper-container"
      :src="`https://app.pixelpaper.io/room/${identifier}?token=${token}`"
      allow="clipboard-read; clipboard-write"
    />

    <!-- Dialog Resume Whiteboard -->
    <dialog-resume-whiteboard
      :visible="dialogResumeWhiteboardVisible"
      @submit="resume"
      @close="$router.push({ name: 'home' })"
    />
    <!-- Dialog Resume Whiteboard -->
  </div>
</template>

<script>
import DialogResumeWhiteboard from '@/views/Home/Whiteboard/Dialogs/DialogResumeWhiteboard'
import Loader from '@/components/Loader'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'Whiteboard',

  components: {
    DialogResumeWhiteboard,
    Loader
  },

  mixins: [
    MixinIdeaRoles,
    MixinUserSubscription
  ],

  data () {
    return {
      loading: false,
      identifier: null,
      token: null,
      pixelPaperDisconnect: false,
      dialogResumeWhiteboardVisible: false,
      sessionTimeout: null
    }
  },

  computed: {
    ...mapGetters('user', ['userFullName']),
    ...mapGetters('idea', ['getIdeaId']),
    ...mapState('ui', ['dialogs'])
  },

  watch: {
    'dialogs.upgrade' (val) {
      if (val === false) {
        this.$router.push({ name: 'home' })
      }
    }
  },

  beforeDestroy () {
    this.$intercom.update({ hide_default_launcher: false })
    this.clearSessionTimeout()
  },

  created () {
    this.$intercom.update({ hide_default_launcher: true })

    if (this.subscriptionIsTeamPro || this.subscriptionIsFreeTrial) {
      this.loading = true
      this.initializationPixelPaper()

      return
    }

    this.setDialogUpgradeState(true)
  },

  methods: {
    ...mapActions('idea/ideaWhiteBoard', [
      'getWhiteboard'
    ]),

    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    initializationPixelPaper () {
      this.getWhiteboard(this.getIdeaId).then(response => {
        this.identifier = response.data.payload.roomId

        axios.post(`https://api.pixelpaper.io/api/access-token/${this.identifier}`,
          { name: this.userFullName },
          { headers: { Authorization: `Bearer ${process.env.VUE_APP_PIXEL_PAPER_PUBLIC_KEY}` } }
        ).then(response => {
          this.token = response.data
          this.loading = false
          this.startSession()
        })
          .catch(() => this.$router.push({ name: 'home' }))
      })
        .catch(() => this.$router.push({ name: 'home' }))
    },

    resume () {
      this.clearSessionTimeout()
      this.dialogResumeWhiteboardVisible = false

      if (this.pixelPaperDisconnect) {
        this.pixelPaperDisconnect = false
      }
    },

    startSession () {
      this.sessionTimeout = setTimeout(() => {
        this.clearSessionTimeout()
        this.dialogResumeWhiteboardVisible = true
        this.pixelPaperDisconnect = true
      }, Number(process.env.VUE_APP_WHITEBOARD_SESSION_DURATION) * 60000)
    },

    clearSessionTimeout () {
      clearTimeout(this.sessionTimeout)
    }
  }
}
</script>

<style lang="scss" scoped>
#pixel-paper-container {
  height: 99vh;
  width: 99%;
}
</style>
