<template>
  <ib-dialog
    :visible="visible"
    :closable="false"
    width="530px"
    empty-header
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :title="$t('dialogs.resumeWhiteboard.pleaseConfirm')"
      :subtitle="$t('dialogs.resumeWhiteboard.doYouWantToContinueUsingTheWhiteboard')"
      :show-illustration="false"
    />
    <!-- /Dialog Content -->

    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :cancel-btn-text="$t('dialogs.resumeWhiteboard.exitToHome')"
        :submit-btn-text="$t('dialogs.resumeWhiteboard.continue')"
        space-between-buttons
        @submit="onResume"
        @cancel="closeDialog"
      />
    </template>
  </ib-dialog>
</template>

<script>
export default {
  name: 'DialogResumeWhiteboard',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onResume () {
      this.$emit('submit')
    },

    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>
